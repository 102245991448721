import React from 'react';
import Hero from '../components/sections/Hero/Hero'
import TechStack from '../components/sections/TechStack/TechStack'
import Section from '../components/elements/Section/Section'
import Bookshelf from '../components/sections/Bookshelf/Bookshelf'
import Certifications from '../components/sections/Certifications/Certifications'
import Portfolio from '../components/sections/Portfolio/Portfolio'

function Home() {
  return (
    <>
      <Section dark>
        <Hero />
      </Section>
      <Section height={'auto'}>
        <TechStack />
      </Section>
      <Section height={'auto'} dark>
        <Certifications />
      </Section>
      <Section height={'auto'} padding={"25px 25px 0 25px"} >
        <Portfolio />
      </Section>
      <Section height={'auto'}>
        <Bookshelf />
      </Section>
    </>
  )
}

export default Home;
