import React from 'react';
import {COLORS, styles} from '../../../styles'
import Button from '../../elements/Button/Button'
import FlexContainer from '../../elements/FlexContainer/FlexContainer'
import './Hero.css'

const resume = 'ResumePetroMoldovan.pdf';

function Hero() {
  const fetchResume = (e: React.SyntheticEvent) => {
    e.preventDefault();
    fetch(`/${resume}`).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = resume;
        alink.click();
      })
    })
  }

  const scrollTo = (e: React.SyntheticEvent, tag: string) => {
    e.preventDefault();
    try {
      const target = document.querySelector(tag);
      target?.scrollIntoView({ behavior: 'smooth' });
    } catch(e) {
      // console.log("e", e)
    }
  }

  return (
    <FlexContainer direction={'column'} flex={'1 1 auto'}>
      <FlexContainer
        flex={'0 1 auto'}
        justify={'space-between'}
        align={'center'}
        maxWidth={'1900px'}>
        <div className={'hero-logo-container'} style={{backgroundColor: COLORS.b2}}>
          <p className={'hero-logo'} style={{color: COLORS.b1}}>P</p>
        </div>
        <Button onClick={fetchResume}>RESUME</Button>
      </FlexContainer>
      <FlexContainer justify={'center'} flex={'1 1 auto'}>
        <FlexContainer direction={'column'} justify={'center'} maxWidth={styles.container.maxWidth} flex={'1 1 auto'}>
          <p className={'mb-xl font-family-secondary'} style={styles.p2}>Hi there! My name is</p>
          <h1 className={'hero-header'} style={styles.p1}>Petro Moldovan.</h1>
          <h2 className={'mb-xl hero-header'} style={styles.label}>I am a developer and security researcher.</h2>
          <p className={'mb-xl'} style={{...styles.label, maxWidth: '700px'}}>
            I am the guy who makes things done. You define a problem and I deliver you high quality and secure code. 
            Let me know if I can help.
          </p>
          <FlexContainer flex={'0 1 auto'}>
            <Button onClick={e => scrollTo(e, "#portfolio")}>
              Checkout my work
            </Button>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  )
}

export default Hero;
