import React from 'react';
import {styles} from '../../../styles';

type SectionProps = {
  children: React.ReactNode,
  dark?: boolean,
  height?: string,
  padding?: string,
}

function Section(props: SectionProps) {
  return (
    <div style={{
      backgroundColor: props.dark ? styles.background.dark.backgroundColor : styles.background.light.backgroundColor,
      height: props.height ? props.height : '100vh',
      padding: props.padding ? props.padding : '25px',
      display: 'flex',
      overflow: 'hidden',
      minHeight: props.height ? props.height : '850px'
    }}>
        {props.children}
    </div>
  )
}

export default Section
