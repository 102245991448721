import React, {useMemo} from 'react';
import './FlexContainer.css'

type FlexContainerProps = {
  id?: string,
  children: React.ReactNode,
  direction?: string,
  justify?: string,
  flex?: string,
  maxWidth?: string,
  alignSelf?: string,
  paddingTop?: string,
  paddingRight?: string,
  paddingLeft?: string,
  paddingBottom?: string,
  align?: string,
  flexWrap?: string,
}

function FlexContainer(props: FlexContainerProps) {
  const flexStyles: any = useMemo(() => {
    return {
      flexDirection: props.direction ? props.direction : 'row',
      justifyContent: props.justify ? props.justify : 'flex-start',
      flex: props.flex ? props.flex : '0 1 auto',
      maxWidth: props.maxWidth ? props.maxWidth : '100%',
      alignSelf: props.alignSelf ? props.alignSelf : '',
      paddingTop: props.paddingTop ? props.paddingTop : '0',
      paddingRight: props.paddingRight ? props.paddingRight : '0',
      paddingLeft: props.paddingLeft ? props.paddingLeft : '0',
      paddingBottom: props.paddingBottom ? props.paddingBottom : '0',
      align: props.align ? props.align : '',
      flexWrap: props.flexWrap ? props.flexWrap : '',
    }
  }, [props])
  
  return (
    <div id={props.id} className='flex-container-default' style={flexStyles}>
      {props.children}
    </div>
  )
}

export default FlexContainer;
