import React from "react";
import {styles} from '../../styles';
import FlexContainer from './FlexContainer/FlexContainer'
import useScreenSize from "../../hooks/useScreenSize";

interface IPortfolioItemProps {
  name: string,
  description: string,
  tags: string[],
  image: any,
  marginBottom?: string,
}

const PortfolioItem = ({name, description, tags, image, marginBottom = "40px"}: IPortfolioItemProps) => {
  const { width } = useScreenSize();
  const isSmallScreen = width < 750;
  const isTabletScreen = width < 900;
  console.log("width", width)
  return (
    <div style={{backgroundColor: styles.background.dark.backgroundColor, padding: '15px', borderRadius: '10px', marginBottom }}>
      <FlexContainer flexWrap="wrap" flex={"1 1 0"}>
        <FlexContainer direction={'row'} justify={isSmallScreen ? "center" : "flex-start"} flex={"1 1 0"}>
          <img src={image} alt={name} style={{ width: isSmallScreen ? "350px" : isTabletScreen ? "400px" : "450px", maxHeight: isSmallScreen ? "200px" : "255px" }}  />
        </FlexContainer>
        <FlexContainer direction={'column'} flex={"1 1 0"} paddingTop="20px" paddingLeft="20px">
          <p className={'mb-md font-family-secondary'} style={{...styles.p2, fontSize: styles.fontSize.l}}>{name}</p>
          <p className={'mb-md'} style={styles.p1}>{description}</p>
          <FlexContainer flexWrap="wrap">
            {
              tags.map(tag => <div key={`${tag}`} className={'mr-md'} style={{...styles.label, fontSize: styles.fontSize.sm}}>{tag}</div>)
            }
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </div>
  )
}

export default PortfolioItem;
