import React from "react";
import {styles} from '../../../styles'
import FlexContainer from '../../elements/FlexContainer/FlexContainer'
import PortfolioItem from "../../elements/PortfolioItem";
import PortfolioCyberSecurityImage from "../../elements/assets/portfolio-cybersecurity.png"
import PortfolioInsuranceImage from "../../elements/assets/portfolio-insurance.png"
import PortfolioOnguardImage from "../../elements/assets/portfolio-shop-onguard2.png"
import PortfolioSchoolImage from "../../elements/assets/portfolio-school.png"
import PortfolioLightShopImage from "../../elements/assets/portfolio-shop-light.png"
import PortfolioAccountingImage from "../../elements/assets/portfolio-accounting.png"


const Portfolio = () => {
  return (
    <FlexContainer id="portfolio" justify={'center'} align={'center'} flex={'1 1 auto'}>
      <FlexContainer maxWidth={'970px'} direction={'column'} flex={'1 1 auto'} justify={'center'}>
        <h2
        style={{
          color: styles.background.dark.backgroundColor,
          marginTop: '40px',
          marginBottom: '40px',
          textAlign:'center',
          fontWeight: 700,
          fontSize: '40px',
        }}>
          Projects Worked On
        </h2>
        <div className="portfolio-items">
          <PortfolioItem
            name={"Cyber security portal"}
            description={"Developed in team an online tool designed for network monitoring, real-time reporting, network research, user management, and workflow for security incidents."}
            tags={["ReactJS", "Django", "Python", "MySQL", "Go", "GraphQL"]}
            image={PortfolioCyberSecurityImage}
          />
          <PortfolioItem
            name={"Insurance broker portal"}
            description={"Designed, developed and deployed a robust insurance portal for both web and mobile platforms (Android & iOS). The portal integrates multiple APIs, manages user accounts, and incorporates a complex onboarding process."}
            tags={["ReactJS", "React Native", "RestAPI"]}
            image={PortfolioInsuranceImage}
          />
          <PortfolioItem
            name={"Online retailer"}
            description={"Developed a frontend that allows users to purchase gadgets and add optional insurance coverage during checkout. The website enables users to select, insure, and buy products in a single process."}
            tags={["JavaScript", "JQuery", "RestAPI"]}
            image={PortfolioOnguardImage}
          />
          <PortfolioItem
            name={"Professional development academy"}
            description={"Integrated and developed a CMS solution for a professional development academy. The system enables content management, course delivery, and user management."}
            tags={["JavaScript", "CMS"]}
            image={PortfolioSchoolImage}
          />
          <PortfolioItem
            name={"Internal accounting tool"}
            description={"Developed a frontend for an internal accounting tool that manages documents, includes social collaboration features, and streamlines the overall company workflow."}
            tags={["AngularJS", "RestAPI"]}
            image={PortfolioAccountingImage}
            marginBottom="0"
          />
        </div>
    
      </FlexContainer>
    </FlexContainer>
  );
};

export default Portfolio;
