import React from 'react';
import FlexContainer from './FlexContainer/FlexContainer'
import {styles} from '../../styles'
import Icon from './Icon'
import EmailIcon from '../elements/assets/EmailIcon'
import GithubIcon from '../elements/assets/GithubIcon'

function Footer() {
  return (
    <footer style={{...styles.background.dark, padding: '0 15px 0 15px'}}>
      <FlexContainer
        paddingTop={'15px'}
        paddingBottom={'15px'}
        align={'center'}
        justify={'center'}>
        <FlexContainer flex={'1 1 auto'} justify={'space-between'} align={'center'} maxWidth={styles.container.maxWidth}>
          <p style={styles.p3}>
            © 2024 Petro Moldovan
          </p>
          <FlexContainer align={'center'}>
            <Icon icon={GithubIcon} marginRight={'15px'} onClick={() => window.open('https://github.com/the-mold')} />
            <Icon icon={EmailIcon} onClick={() => window.location.href='mailto:petro.moldovan@gmail.com'} />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </footer>
  )
}

export default Footer;
